import { Box, Paper, Typography, Container } from '@mui/material';
import "./index.css";

import EmailForm from '../../PageElements/EmailForm/EmailForm';

import tjpbLogo from '../../../sharedImgs/logo_tjpb.png';
import pjerj from '../../../sharedImgs/TJRJLOGO.svg';
import ufrjFnd from '../../../sharedImgs/UFRJFND.svg';
import Cnj from '../../../sharedImgs/premio_cnj.jpg'
import JuizaViviane from '../../../sharedImgs/premio_cnj_juiza_viviane.png'
import ShellIniciativa from '../../../sharedImgs/selo_shell_iniciativa_jovem_2022.png'
import IniciativaImpacto from '../../../sharedImgs/selo_inovativa_impacto.png'
import SeloMulheres from '../../../sharedImgs/selo_mulheres_libertas.jpg'
import seloImpact from '../../../sharedImgs/selo_impact.png'
import DaLogo from '../../../sharedImgs/Da.svg';
import elaProtegidaBanner from '../../../sharedImgs/elaProtegidaLaptop.png';
import mariaPenhaIphone from '../../../sharedImgs/mariaPenhaIphone.svg';

import { useNavigate } from 'react-router-dom';

export default function Home() {
    const navigate = useNavigate();

    const checkRoute = (route) => {
        if (route) {
            navigate('/')
        }
    }

    const goToSolutions = () => {
        navigate("/solutions")
    }


    return (
        <>
            <section id='presentation'>
                <Box className="menu">
                    <Typography variant='h1' style={{ marginTop: '10px', fontWeight: 'bold', fontSize: 40, color: '#5b5750' }}>Conheça o impacto da Direito Ágil</Typography>
                    <Box>
                        <p className="bullets-text">+ 10.000 mulheres atendidas</p>
                        <p className="bullets-text">+ inteligência para gestão das cidades</p>
                        <p className="bullets-text">+ mais de 100 cidades impactadas</p>
                        <p className="bullets-text">+ de 500.000 horas economizadas de serviços públicos</p>
                    </Box>
                    <a className="contact-button" href="#contact" onClick={() => checkRoute("contact")} variant="contained" >
                        Entre em contato conosco
                    </a>
                </Box>
            </section >
            <section id="cases">
                <Container className='ela-protegida-container'>
                    <img className="image-layout-large" src={elaProtegidaBanner} alt="Sistema elaProtegida em um dispositivo laptop" />

                    <Box className='solutions-flex-box-end'>
                        <Typography variant='h3' style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold', fontSize: 30, color: '#5b5750' }}>Desenvolvemos tecnologias de impacto para atender projetos e grupos vulneráveis</Typography>
                        <Typography>Conheça o nosso novo sistema,o <b style={{ color: '#7b61ff' }}>elaProtegida</b>, que representa a inovação para a gestão pública e proteção de mulheres em situação de violência nas cidades.</Typography>
                        <Typography >A plataforma digital garante geração discreta de denúncias de violência doméstica e violência extrafamiliar e eficiência na gestão integrada dos atendimentos e encaminhamentos, que hoje são realizados de forma manual ou sem controle dos dados,
                            além de permitir o cálculo de risco de vida de cada vítima para priorizar atendimentos e um painel de controle completo para melhor tomada de decisões baseadas em evidências.</Typography>
                        <a onClick={() => goToSolutions()} style={{ padding: '20px', backgroundColor: '#7b61ff', color: 'white', marginTop: 20, borderRadius: '20px' }} variant="contained" >
                            Saiba Mais
                        </a>
                    </Box>
                </Container>
                <Container className='maria-penha-container'>
                    <Box className='solutions-flex-box-start'>
                        <Typography variant='h3' style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold', fontSize: 30, color: '#5b5750' }}>Criamos a metodologia utilizada no  <b style={{ color: '#7b61ff' }}>Maria da Penha Virtual</b></Typography>
                        <Typography>Nesse sentido, criamos soluções que são a validação concreta do modelo teórico de concretização de direitos humanos concebido pelo grupo que pode ser replicável em larga escala e customizado com um design específico para a proteção de grupos vulneráveis,</Typography>
                        <Typography>bem como para atender diversas outras demandas em que se vislumbra a tecnologia como solução para eliminar tarefas repetitivas em fluxos processuais.</Typography>
                        <a onClick={() => goToSolutions()} style={{ padding: '20px', backgroundColor: '#7b61ff', color: 'white', marginTop: 20, borderRadius: '20px' }} variant="contained" >
                            Leia nossas publicações
                        </a>
                    </Box>
                    <img className="image-layout-large" src={mariaPenhaIphone} alt="Projeto Maria da Penha Virtual em um dispositivo mobile" />
                </Container>
            </section >
            <section id="partners">
                <Box className='partners-box'>
                    <Typography style={{ marginTop: '0', fontWeight: 'bold', fontSize: 30, color: '#5b5750' }}>Parceiros</Typography>
                    <Container className="image-grid">
                        <Box className='partners-flex-box'>
                            <img className="image-layout" src={pjerj} alt="Logo do Tribunal de Justiça do Rio de Janeiro, TJRJ" />
                            <img className="image-layout" src={ufrjFnd} alt="Logo da Faculdade UFRJ" />
                            <img className="image-layout" src={tjpbLogo} alt="Logo do Tribunal de Justiça da Paraíba, TJPB" />
                        </Box>
                    </Container>
                </Box>
                <Box className='certifications-box'>
                    <Typography style={{ marginTop: '0', fontWeight: 'bold', fontSize: 30, color: '#5b5750' }}>Premiações e certificados</Typography>
                    <Container className="image-grid">
                        <Box className='certifications-flex-box'>
                            <img className="image-layout" src={ShellIniciativa} alt="selo Shell Iniciativa Jovem" />
                            <img className="image-layout" src={Cnj} alt="Premiacao do CNJ" />
                            <img className="image-layout" src={JuizaViviane} alt="Premio juiza viviane cnj" />
                        </Box>
                        <Box className='certifications-flex-box'>
                            <img className="image-layout" src={IniciativaImpacto} alt="Inovativa Impacto" />
                            <img className="image-layout" src={seloImpact} alt="selo Impact" />
                            <img className="image-layout" src={SeloMulheres} alt="Selo Mulheres Libertas" />
                        </Box>
                    </Container>
                </Box>
            </section>
            <section id="contact">
                <Container>
                    <Paper className='contactus-container'>
                        <Box className='header-box'>
                            <h2 className='text-title'>Entre em contato conosco!</h2>
                            <p className='contactus-text'>Nos mande uma mensagem e iremos te responder em até 48 horas.</p>
                        </Box>
                        <Box className='form-box'>
                            <EmailForm />
                            <div className='contactus-container-imgs'>
                                <img style={{ width: '100px' }} className='contactus-container-logo' src={DaLogo} alt="simbolo da direito agil" />
                            </div>
                        </Box>
                    </Paper>
                </Container>
            </section>
        </>
    );
}