import Home from "./Components/Pages/Home";
import Navbar from "./Components/PageElements/Navbar";
import Footer from "./Components/PageElements/Footer";
import { Fab } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

function App() {
  return (
    <>
      <Navbar />
      <div
        style={{
          position: "fixed",
          padding: 60,
          zIndex: 9999,
          float: 'right',
          bottom: 0,
          right: 0,
          display: "flex",
        }}
      >
        <Fab
          color="success"
          onClick={() => window.open("https://wa.me/5521995925924")}
        >
          <CallIcon />
        </Fab>
      </div>
      <Home />
      <Footer />
    </>
  );
}

export default App;
